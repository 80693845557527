import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { AuthContext } from '@/context/AuthContext'
import NavLinkElement from './NavLinkElement'
import {
    faChartPie,
    faClipboardList,
    faMoneyCheckDollar,
    faObjectGroup,
    faPerson,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function PrivilegedUserLinks() {
    const { t } = useTranslation()
    const { user, logout } = useContext(AuthContext)

    return (
        <>
            {user.is_operator_staff && (
                <>
                    <NavLinkElement
                        to="/list-policy"
                        data-tip={t('Πολιτικές Τιμολόγησης')}
                    >
                        <FontAwesomeIcon icon={faClipboardList} />

                        <div className="hidden 2xl:inline ml-1">
                            {t('Πολιτικές Τιμολόγησης')}
                        </div>
                    </NavLinkElement>

                    <NavLinkElement
                        to="/list-policy-group"
                        data-tip={t('Ομάδες Φορτιστών')}
                    >
                        <FontAwesomeIcon icon={faObjectGroup} />

                        <div className="hidden 2xl:inline ml-1">
                            {t('Ομάδες Φορτιστών')}
                        </div>
                    </NavLinkElement>
                </>
            )}
            {user.is_rae_admin ||
                (user.is_superuser && (
                    <>
                        <NavLinkElement
                            to="/statistics"
                            data-tip={t('Στατιστικά')}
                        >
                            <FontAwesomeIcon icon={faChartPie} />

                            <div className="hidden 2xl:inline ml-1">
                                {t('Στατιστικά')}
                            </div>
                        </NavLinkElement>
                    </>
                ))}
        </>
    )
}

function RegularUserLinks() {
    const { t } = useTranslation()
    const { user } = useContext(AuthContext)

    return (
        <>
            {user.isAuthenticated && user.profile ? (
                <>
                    <NavLinkElement to="/dashboard">
                        <FontAwesomeIcon icon={faMoneyCheckDollar} />
                        <div className="hidden 2xl:inline">
                            <div className="hidden lg:inline ml-1">
                                {t('Σύγκριση')}
                            </div>
                        </div>
                    </NavLinkElement>
                    <NavLinkElement to="/user-profile">
                        <FontAwesomeIcon icon={faPerson} />
                        <div className="hidden 2xl:inline">
                            <div className="hidden lg:inline ml-1">
                                {t('Το προφίλ μου')}
                            </div>
                        </div>
                    </NavLinkElement>
                </>
            ) : null}
        </>
    )
}

function NavLinks() {
    return (
        <div className="flex gap-2 rounded-xl bg-[#0f2254] mr-3">
            <RegularUserLinks />
            <PrivilegedUserLinks />
        </div>
    )
}

export default NavLinks
