import React, { useState, useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { DataContext } from '@/context/DataContext'
import { useUserPosition } from '@/modules/position/context'
import { RoutingContext } from '@/modules/routing/context'
import { CircleButton } from '@/components/buttons/CircleButton'
import { OpeningTimesComponent } from './OpeningTimes'
import {
    formatDateTime,
    formatEditedBefore,
    alertTimeElapsed,
} from '@/lib/utils'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'

import { LocationDetailsError } from '@/components/locations/LocationDetailsError'
import EvseList from '../evses/EvseList'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faXmark,
    faClockRotateLeft,
    faLocationCrosshairs,
    faDiamondTurnRight,
    faDownLeftAndUpRightToCenter,
    faArrowUpRightFromSquare,
    faExclamationCircle,
    faChevronLeft,
    faImages,
    faInfoCircle,
    faChargingStation,
    faClock,
} from '@fortawesome/free-solid-svg-icons'
import GoogleLogo from '@/assets/img/google-logo.png'

import { LocationInformation } from './LocationInformation'
import { useMapZoomTo } from '@/hooks/useMapZoomTo'
import { isMobile } from '@/lib/utils'
import { openGmaps } from '@/lib/google-maps'

function LocationDetails({ selectedLocation, onDashboard = false }) {
    const { setSelectedLocation, selectedLocationDetails } =
        useContext<any>(DataContext)
    const { userPosition } = useUserPosition()
    const { getRoute } = useContext(RoutingContext)
    const { t } = useTranslation()

    const [minimized, setMinimized] = useState(false)
    useEffect(() => {
        setMinimized(false)
    }, [selectedLocation])

    const handleMapZoom = useMapZoomTo()

    if (!selectedLocation) return null

    if (!selectedLocationDetails || selectedLocationDetails == null)
        return <LocationDetailsError />

    //if (dataLoading) return <LocationDetailsError />
    const lastUpdate = formatDateTime(selectedLocationDetails.last_updated)

    const updatedLongAgo = alertTimeElapsed(
        selectedLocationDetails.last_updated,
        {
            days: 1,
        }
    )
    const CloseButton = () => {
        return (
            <div className="flex flex-row gap-1 ml-auto items-center">
                <CircleButton
                    icon={faDownLeftAndUpRightToCenter}
                    tooltip={t('Ελαχιστοποίηση')}
                    onClick={() => setMinimized(true)}
                    className="text-xs "
                />

                <CircleButton
                    icon={faXmark}
                    tooltip={t('Κλείσιμο')}
                    onClick={() => {
                        setSelectedLocation(null)
                    }}
                />
            </div>
        )
    }

    const BackButton = () => {
        return (
            <button
                className="p-1 cursor-pointer tooltip tooltip-left ml-auto"
                data-tip={t('Όλοι οι Σταθμοί')}
                onClick={() => {
                    setSelectedLocation(null)
                }}
            >
                <FontAwesomeIcon icon={faChevronLeft} />
            </button>
        )
    }

    if (minimized)
        return (
            <div className="p-4 w-full text-xs md:rounded-lg bg-base-100 shadow-2xl border border-gray-300 ">
                <div className="flex flex-row w-full px-2 py-0 items-center gap-2">
                    <div className="font-bold">
                        {selectedLocationDetails.name}{' '}
                    </div>
                    <button
                        className="btn btn-xs btn-ghost btn-circle ml-auto"
                        onClick={() => {
                            setMinimized(false)
                        }}
                    >
                        <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                    </button>
                </div>
            </div>
        )

    return (
        <>
            <div
                className={`relative p-4 w-full h-full text-xs flex flex-col border border-gray-300 overflow-y-hidden ${
                    onDashboard
                        ? 'shadow-inner border-none'
                        : 'md:rounded-lg bg-base-100 shadow-2xl'
                }
            ${isMobile() ? 'border-none bg-none shadow-none pt-0' : ''}
            `}
            >
                <div className="flex flex-row items-baseline content-baseline">
                    <div className="flex flex-col">
                        <h2
                            className="text-sm md:text-lg font-bold cursor-pointer"
                            onClick={() => {
                                handleMapZoom(
                                    selectedLocation.geometry.coordinates
                                )
                            }}
                        >
                            {selectedLocationDetails.name}
                        </h2>
                        <div className="block">
                            <OpeningTimesComponent
                                opening_times={
                                    selectedLocationDetails.opening_times
                                }
                                badgeOnly={true}
                            />
                        </div>
                    </div>

                    {!isMobile() ? (
                        onDashboard ? (
                            <BackButton />
                        ) : (
                            <CloseButton />
                        )
                    ) : null}
                </div>

                <Tabs className="w-full h-full min-h-[30vh]">
                    <TabList className="tabs mb-4 sticky mr-auto mt-2 bg-base-200 bg-opacity-30 rounded p-1 px-3 flex gap-1 justify-start">
                        <Tab className="tab tab-sm py-1 mx-[1px] text-xs">
                            <span>
                                <FontAwesomeIcon
                                    icon={faChargingStation}
                                    className="mr-1"
                                />
                                {t('Φορτιστές')}
                            </span>
                        </Tab>
                        <Tab className="tab tab-sm py-1 mx-[1px] text-xs">
                            <span>
                                <FontAwesomeIcon
                                    icon={faClock}
                                    className="mr-1"
                                />
                                {t('Ωράριο')}
                            </span>
                        </Tab>
                        <Tab className="tab tab-sm py-1 mx-[1px] text-xs">
                            <span>
                                <FontAwesomeIcon
                                    icon={faInfoCircle}
                                    className="mr-1"
                                />
                                {t('Πληροφορίες')}
                            </span>
                        </Tab>
                        {selectedLocationDetails.images?.length > 0 ? (
                            <Tab className="tab tab-sm py-1 mx-[1px] text-xs">
                                <span>
                                    <FontAwesomeIcon
                                        icon={faImages}
                                        className="mr-1"
                                    />

                                    {t('Φωτογραφίες')}
                                </span>
                            </Tab>
                        ) : null}
                    </TabList>
                    <TabPanel>
                        {selectedLocationDetails ? (
                            <EvseList
                                evses={selectedLocationDetails.evses}
                                className="w-full mt-2 h-full max-h-max overflow-y-auto md:overflow-y-hidden overflow-x-hidden"
                            />
                        ) : null}
                    </TabPanel>
                    <TabPanel>
                        {selectedLocationDetails.opening_times ? (
                            <div className="p-4 rounded-lg text-sm bg-base-200 bg-opacity-30 flex flex-col gap-3 mb-4">
                                <OpeningTimesComponent
                                    defaultExpanded={true}
                                    charging_when_closed={
                                        selectedLocationDetails.charging_when_closed
                                    }
                                    opening_times={
                                        selectedLocationDetails.opening_times
                                    }
                                />
                            </div>
                        ) : null}
                    </TabPanel>
                    <TabPanel>
                        <div className="p-4 rounded-lg text-sm bg-base-200 bg-opacity-30  flex flex-col gap-3 mb-4">
                            <LocationInformation
                                locationData={selectedLocationDetails}
                            />
                        </div>
                    </TabPanel>
                    {selectedLocationDetails.images?.length > 0 ? (
                        <TabPanel>
                            <div className="grid grid-cols-2 gap-4 p-2 overflow-y-auto h-full">
                                {selectedLocationDetails.images.map(
                                    (image, index) => (
                                        <a
                                            key={index}
                                            href={image.url}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <img
                                                src={image.url}
                                                alt=""
                                                className="object-cover rounded-lg shadow-lg"
                                            />
                                        </a>
                                    )
                                )}
                            </div>
                        </TabPanel>
                    ) : null}
                </Tabs>

                <div className="flex flex-row items-center content-center w-full mt-auto pt-2 ">
                    <>
                        <div className="md:mr-auto btn-group btn-group-horizontal ">
                            <button
                                className="btn tooltip btn-sm md:btn-md"
                                data-tip={t('Εστίαση')}
                                onClick={() =>
                                    handleMapZoom(
                                        selectedLocation.geometry.coordinates
                                    )
                                }
                            >
                                <FontAwesomeIcon icon={faLocationCrosshairs} />{' '}
                            </button>
                            <button
                                className={`btn tooltip btn-sm md:btn-md ${
                                    !userPosition ? 'btn-disabled' : ''
                                }`}
                                data-tip={t('Οδηγίες για το σταθμό')}
                                onClick={() =>
                                    getRoute({
                                        origin: [
                                            userPosition.longitude,
                                            userPosition.latitude,
                                        ],
                                        destination:
                                            selectedLocation.geometry
                                                .coordinates,
                                    })
                                }
                            >
                                <FontAwesomeIcon icon={faDiamondTurnRight} />{' '}
                            </button>
                            <button
                                className="btn tooltip btn-sm md:btn-md flex items-center"
                                data-tip={t('Οδηγίες στο GoogleMaps')}
                                onClick={() => {
                                    openGmaps(
                                        userPosition,
                                        selectedLocation.geometry.coordinates
                                    )
                                }}
                            >
                                {/* <FontAwesomeIcon icon={faGoogle} className="ml-2" /> */}
                                <img
                                    src={GoogleLogo}
                                    className="h-6"
                                    alt="Google logo"
                                />
                                {t('Οδηγίες')}
                            </button>
                        </div>

                        <span
                            className="ml-auto text-gray-600 mx-2 tooltip tooltip-left font-medium "
                            data-tip={`Ο σταθμός ενημερώθηκε ${lastUpdate}`}
                        >
                            {updatedLongAgo ? (
                                <div className="indicator">
                                    <span className="indicator-item">
                                        <FontAwesomeIcon
                                            className="text-orange-500 ml-2"
                                            icon={faExclamationCircle}
                                        />
                                    </span>
                                    <div className="">
                                        <FontAwesomeIcon
                                            icon={faClockRotateLeft}
                                            className="mr-2"
                                        />
                                        {formatEditedBefore(
                                            selectedLocationDetails.last_updated
                                        )}
                                    </div>
                                </div>
                            ) : (
                                <>
                                    <FontAwesomeIcon
                                        icon={faClockRotateLeft}
                                        className="mr-2"
                                    />
                                    {formatEditedBefore(
                                        selectedLocationDetails.last_updated
                                    )}
                                </>
                            )}
                        </span>
                    </>
                </div>
            </div>
        </>
    )
}

export default LocationDetails
