import React, { useContext, useEffect } from 'react'
import { DataContext } from '@/context/DataContext'
import { AuthContext } from '@/context/AuthContext'
import { useUserPosition } from '@/modules/position/context'

import { ParameterWalkthrough } from '@/components/dashboard/ParameterWalkthrough'
import { PriceDashboardLayout } from '@/components/dashboard/DashboardLayout'

const UserPositionTrack = ({ initialPageLoad }) => {
    const { startTracking } = useUserPosition()

    useEffect(() => {
        if (!initialPageLoad) return
        startTracking()
    }, [])
    return null
}

// Memoized HOC to pass the map context to the wrapped component
const withParentContext = (WrappedComponent) => {
    const MemoizedComponent = React.memo(WrappedComponent)

    return function WithMapContext(props) {
        // Dashboard Parent
        const { user } = useContext(AuthContext)

        // Dashboard Layout
        const {
            initialPageLoad,
            data,
            selectedLocation,
            setSelectedLocation,
            dataLoading,
        } = useContext(DataContext)

        return (
            <>
                <MemoizedComponent
                    {...props}
                    initialPageLoad={initialPageLoad}
                    user={user}
                    data={data}
                    selectedLocation={selectedLocation}
                    setSelectedLocation={setSelectedLocation}
                    dataLoading={dataLoading}
                />

                {/* 
                    UserPositionTrack is rendered as sibling, to avoid
                    rerendering the whole dashboard layout when the 
                    useUserPosition hook changes (all the time, because
                    the user position is updated via the browser API,
                    and at least the timestamp changes constantly.
                    )
                */}
                <UserPositionTrack initialPageLoad={initialPageLoad} />
            </>
        )
    }
}

const PriceDashboard = withParentContext(function PriceDashboard({
    initialPageLoad,
    user,
    data,
    selectedLocation,
    setSelectedLocation,
    dataLoading,
}) {
    // First time, show the parameter walkthrough
    if (user?.isAuthenticated === false && initialPageLoad)
        return <ParameterWalkthrough />

    return (
        <PriceDashboardLayout
            data={data}
            selectedLocation={selectedLocation}
            setSelectedLocation={setSelectedLocation}
            dataLoading={dataLoading}
        />
    )
})

export default PriceDashboard
