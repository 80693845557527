import React from 'react'
import { useTranslation } from 'react-i18next'

function VersionBanner() {
    const { t } = useTranslation()
    const app_version = import.meta.env.VITE_APP_VERSION || 'beta'

    return (
        <span
            style={{
                letterSpacing: '1px',
            }}
            className=" text-xl text-sky-300 tooltip tooltip-bottom"
            data-tip={t('home.version_banner')}
        >
            {' '}
            ({app_version})
        </span>
    )
}

export { VersionBanner }
