import React, { useContext, useEffect, useState } from 'react'
import { ApiError } from '@/api/index'
import { AuthContext } from '@/context/AuthContext'
import { useForm } from 'react-hook-form'
import RouteBase from '@/routes/RouteBase'
import LogoImg from '@/assets/img/favicon.webp'
import { useNavigate, Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faSpinner } from '@fortawesome/free-solid-svg-icons'

const ForgotPassword = () => {
    const { AuthenticatedAPI, error, setError } = useContext(AuthContext)
    const [success, setSuccess] = useState(false)
    const [loading, setLoading] = useState(false)
    const {
        handleSubmit,
        register,
        formState: { errors: FormErrors },
    } = useForm()

    const ApiErrors = error as ApiError

    // Clear error on unmount
    useEffect(() => {
        return () => {
            setError(null)
        }
    }, [])

    const requestPasswordReset = (data) => {
        const { email } = data
        setLoading(true)
        AuthenticatedAPI.auth
            .authPasswordResetCreate({
                data: {
                    email,
                },
            })

            .then((response) => {
                setSuccess(true)
                setLoading(false)
            })
            .catch((err) => {
                console.log('error', err.body)
                setError(err.body)
                setLoading(false)
            })
    }

    const SuccessMessageComponent = () => {
        return (
            <>
                {success && (
                    <div className="flex flex-row items-center mt-3 text-sm text-green-700 font-bold">
                        <FontAwesomeIcon
                            icon={faCheckCircle}
                            className=" mr-3"
                        />
                        <span>Το μήνυμα έχει σταλεί</span>
                    </div>
                )}
            </>
        )
    }

    const SubmitButtonComponent = () => {
        return (
            <button type="submit" className="mt-4 btn btn-outline mr-auto">
                {loading && (
                    <FontAwesomeIcon
                        icon={faSpinner}
                        className="animate-spin mr-2"
                    />
                )}
                {success ? 'Αποστολή ξανά' : 'Αποστολή'}
            </button>
        )
    }

    const FormComponent = () => {
        return (
            <form
                onSubmit={handleSubmit(requestPasswordReset)}
                className="flex flex-col p-5 px-7 "
            >
                <h1 className=" text-xl  my-4">Ανάκτηση κωδικού πρόσβασης </h1>

                <div className="form-group mb-5">
                    <p htmlFor="email" className="text-sm max-w-3 prose mb-5">
                        Καταχωρήστε το με το οποίο έχετε δημιουργήσει το
                        λογαριασμό σας. Θα σας αποσταλεί ένα μήνυμα ηλεκτρονικού
                        ταχυδρομείου με οδηγίες για την ανάκτηση του κωδικού
                        πρόσβασης σας.
                    </p>
                    <label htmlFor="email" className="font-medium">
                        e-mail
                    </label>
                    <input
                        {...register('email', {
                            required: 'This field is required.',
                        })}
                        type="text"
                        placeholder="E-mail"
                        className="w-full input input-bordered input-primary"
                        id="email"
                        name="email"
                    />
                    {FormErrors.email && (
                        <small className="text-error">
                            {FormErrors.email.message}
                        </small>
                    )}
                    {/* @ts-expect-error email is a specific key of this endpoint */}
                    {ApiErrors?.email && (
                        <small className="text-error">
                            {ApiErrors.email[0]}
                        </small>
                    )}
                </div>

                {/* {ApiError?.non_field_errors &&
                    ApiError.non_field_errors.map((err, index) => {
                        return (
                            <small className="text-error" key={index}>
                                {err}
                            </small>
                        )
                    })} */}

                <SubmitButtonComponent />
                <SuccessMessageComponent />

                <div className="mt-4 ml-auto">
                    <Link
                        to="/login"
                        className="link text-blue-900 font-medium text-sm mt-4 ml-auto"
                    >
                        Επιστροφή στην σελίδα σύνδεσης
                    </Link>
                </div>
            </form>
        )
    }

    return (
        <RouteBase classNames="flex flex-col content-center items-center h-full">
            <div className="h-full w-full flex flex-col">
                <div className="m-auto rounded-lg bg-blue-100 bg-opacity-20 border border-blue-100 p-4 flex  flex-col gap-3 ">
                    <div className="h-content flex flex-col md:flex-row ">
                        <img
                            src={LogoImg}
                            alt=""
                            width="150"
                            className="m-auto"
                        />

                        <div className="divider lg:divider-horizontal"></div>

                        <FormComponent />
                    </div>
                </div>
            </div>
        </RouteBase>
    )
}

export default ForgotPassword
