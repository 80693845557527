import React, { useRef, useContext } from 'react'
import { NavLink } from 'react-router-dom'

import { Menu } from 'react-daisyui'
import { useTranslation } from 'react-i18next'
import NavbarElement from './NavbarElement'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faUserSlash,
    faSignOut,
    faUser,
    faScrewdriverWrench,
    faChartPie,
} from '@fortawesome/free-solid-svg-icons'

import { AuthContext } from '@/context/AuthContext'
import { SidebarItem } from '@/components/sidebar/SidebarItems'

function NavAuthComponent({ expanded }) {
    const { t } = useTranslation()
    const { user, logout } = useContext(AuthContext)

    const Avatar = () => {
        const companyImage = user.company
            ? `https://electrokinisi.yme.gov.gr/public/images/${user.company.ProfileImage}`
            : null
        const profileImage = user.profile_picture ? user.profile_picture : null

        if (companyImage && !expanded)
            return (
                <div className=" m-1">
                    <img
                        src={companyImage}
                        className="shadow-inner rounded-full ring-2 ring-white"
                    />
                </div>
            )

        if (companyImage)
            return (
                <div className="flex flex-row gap-1 items-center font-medium w-full px-1">
                    <div className="w-10 p-1">
                        <img
                            src={companyImage}
                            className="rounded-full shadow-inner ring-2 ring-white"
                        />
                    </div>{' '}
                    <div className="truncate">{user.username} </div>
                </div>
            )

        if (profileImage && !expanded)
            return (
                <div className="m-2">
                    <img
                        src={profileImage}
                        className="shadow-inner rounded-full ring-2 h-10 ring-white"
                    />
                </div>
            )

        if (profileImage)
            return (
                <div className="flex flex-row gap-1 items-center font-medium w-full px-1">
                    <div className="w-10 p-1">
                        <img
                            src={profileImage}
                            className="rounded-full shadow-inner ring-2 ring-white"
                        />
                    </div>{' '}
                    <div className="truncate">{user.username} </div>
                </div>
            )

        return (
            <div className="flex flex-row gap-1 items-center justify-center font-medium w-full ">
                <FontAwesomeIcon className="" icon={faUser} />
                {expanded ? <span>{user.username} &nbsp;</span> : null}
            </div>
        )
    }

    const MenuItems = () => {
        return (
            <>
                <li>
                    <NavLink to="/user-profile" className="text-left">
                        <FontAwesomeIcon icon={faUser} />
                        {t('Προφίλ')}
                    </NavLink>
                </li>
                {user.is_superuser || user.is_rae_admin ? (
                    <>
                        <li>
                            <NavLink
                                to={`${window.location.origin}/admin/`}
                                className="text-left"
                                target="_blank"
                            >
                                {/* externalLink={true} */}
                                <FontAwesomeIcon icon={faScrewdriverWrench} />
                                {t('Διαχείριση')}
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to={`/statistics`} className="text-left">
                                {/* externalLink={true} */}
                                <FontAwesomeIcon icon={faChartPie} />
                                {t('Στατιστικά')}
                            </NavLink>
                        </li>
                    </>
                ) : null}

                <li>
                    <button onClick={logout}>
                        <FontAwesomeIcon icon={faSignOut} />
                        {t('Αποσύνδεση')}
                    </button>
                </li>
            </>
        )
    }

    const FullComponent = () => {
        if (user.isAuthenticated) {
            return (
                <div className="w-full dropdown dropdown-top md:dropdown-right dropdown-end ">
                    <label
                        tabIndex={0}
                        className="flex text-secondary cursor-pointer"
                    >
                        <Avatar />
                    </label>
                    <ul
                        tabIndex={0}
                        className="my-3 p-2 shadow menu menu-compact dropdown-content rounded-box bg-primary w-72"
                    >
                        <MenuItems />
                    </ul>
                </div>
            )
        }

        return (
            <Menu
                horizontal
                className="p-0 tooltip tooltip-right"
                data-tip={t('Σύνδεση')}
            >
                <NavbarElement to="login" className="btn btn-ghost btn-circle">
                    <FontAwesomeIcon icon={faUserSlash} />
                </NavbarElement>
            </Menu>
        )
    }

    return (
        <SidebarItem
            expanded={expanded}
            minimizedContent={<FullComponent />}
            tooltip={null}
        >
            <FullComponent />
        </SidebarItem>
    )
}

export default NavAuthComponent
