import React from 'react'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faFilter,
    faArrowLeft,
    faInfoCircle,
    faLocationDot,
    faLocationPinLock,
    faMagnifyingGlass,
    faHandHoldingDollar,
    faCarAlt,
} from '@fortawesome/free-solid-svg-icons'
import { getScreenSize, isMobile } from '@/lib/utils'

// import { content_gr, content_en } from '@/assets/empty_search'

const EmptySearchPlaceholder = () => {
    const { t, i18n } = useTranslation()

    // const content = i18n.language === 'el' ? content_gr : content_en

    return (
        <div className="w-full h-full flex rounded-lg  shadow-inner min-h-max py-14 overflow-y-auto">
            <div className="m-auto w-10/12 text-gray-500">
                <h1 className="text-2xl font-bold ">
                    <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />
                    {t('Δεν υπάρχουν αποτελέσματα')}
                </h1>
                {isMobile() ? null : (
                    <p className="font-light mt-5">
                        <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
                        {t('Χρησιμοποιείστε την')}{' '}
                        <span className="font-bold">{t('εργαλειοθήκη')}</span>{' '}
                        {t('αριστερά για να κάνετε μια νέα αναζήτηση.')}
                    </p>
                )}
                <ul className="my-5 p-2 ">
                    <li>
                        <FontAwesomeIcon
                            icon={faHandHoldingDollar}
                            className="mr-2"
                        />
                        {t('Ορίστε το')}{' '}
                        <span className="text-blue-400">{t('ποσό')}</span>{' '}
                        {t(
                            'που επιθυμείτε να φορτίσετε (σε kWh, ευρώ, ή λεπτά φόρτισης).'
                        )}
                    </li>
                    <li>
                        <FontAwesomeIcon icon={faFilter} className="mr-2" />
                        {t('Εφαρμόστε')}{' '}
                        <span className="text-blue-400">{t('φίλτρα')}</span>,{' '}
                        {t(
                            'λ.χ. τύπος βύσματος, τύπος ρεύματος (συνεχές, εναλλασόμενο τριφασικό κ.λ.π.), δυνατότητες φορτιστή κ.α.'
                        )}
                    </li>
                    <li>
                        <FontAwesomeIcon icon={faCarAlt} className="mr-2" />
                        {t('Επιλέξτε το')}{' '}
                        <span className="text-blue-400">{t('όχημα')}</span>{' '}
                        {t(
                            'σας για να εφαρμόσετε αυτόματα τα χαρακτηριστικά του (μόνο για εγγεγραμμένους χρήστες)'
                        )}
                    </li>
                </ul>
                <p>
                    {t('Όταν είστε έτοιμοι, πατήστε το κουμπί')}{' '}
                    <span className="px-4 py-1 bg-secondary text-white font-bold rounded-lg text-sm mx-2 whitespace-nowrap">
                        <FontAwesomeIcon
                            icon={faMagnifyingGlass}
                            className="mr-2"
                        />
                        {t('Αναζήτηση')}
                    </span>
                </p>
                <p className="my-3 mt-6">
                    {t(
                        'Αφού πραγματοποιήσετε την αναζήτηση σας μπορείτε να φιλτράρετε περαιτέρω τα αποτελέσματα με την εργαλείοθήκη επάνω.'
                    )}
                </p>
                <p className="my-3">
                    {t(
                        'Μπορείτε ανά πάσα στιγμή να αλλάξετε τις παραμέτρους και να πραγματοποιήσετε νέα αναζήτηση.'
                    )}
                </p>
                <div className="shadow px-3 py-3 rounded-lg flex flex-col lg:flex-row gap-3  items-center mt-4">
                    <FontAwesomeIcon
                        icon={faLocationDot}
                        className="text-2xl m-3 opacity-50 text-blue-500"
                    />
                    <p>
                        {t(
                            'Για καλύτερα αποτελέσματα, επιτρέψτε στην εφαρμογή να έχει πρόσβαση στην τοποθεσία σας. Στη συνέχεια μπορείτε να ορίσετε την ακτίνα της αναζήτησης σας.'
                        )}
                        <br />
                        <strong className="text-xs bg-green-200 rounded-xl p-1">
                            <FontAwesomeIcon
                                icon={faLocationPinLock}
                                className="mr-2"
                            />
                            {t(
                                'Η εφαρμογή δεν αποθηκεύει ποτέ την τοποθεσία σας.'
                            )}
                        </strong>
                    </p>
                </div>
            </div>

            {/* <div
                dangerouslySetInnerHTML={{
                    __html: content,
                }}
            ></div> */}
        </div>
    )
}

export { EmptySearchPlaceholder }
