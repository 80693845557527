/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ChargePolicy } from '../models/ChargePolicy';
import type { PolicyGroup } from '../models/PolicyGroup';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ChargingCostService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns ChargePolicy 
     * @throws ApiError
     */
    public chargingCostChargePoliciesList(): CancelablePromise<Array<ChargePolicy>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/charging-cost/charge-policies/',
        });
    }

    /**
     * @returns ChargePolicy 
     * @throws ApiError
     */
    public chargingCostChargePoliciesCreate({
data,
}: {
data: ChargePolicy,
}): CancelablePromise<ChargePolicy> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/charging-cost/charge-policies/',
            body: data,
        });
    }

    /**
     * @returns ChargePolicy 
     * @throws ApiError
     */
    public chargingCostChargePoliciesRead({
id,
}: {
/**
 * A unique integer value identifying this Πολιτική Τιμολόγησης.
 */
id: number,
}): CancelablePromise<ChargePolicy> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/charging-cost/charge-policies/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns ChargePolicy 
     * @throws ApiError
     */
    public chargingCostChargePoliciesUpdate({
id,
data,
}: {
/**
 * A unique integer value identifying this Πολιτική Τιμολόγησης.
 */
id: number,
data: ChargePolicy,
}): CancelablePromise<ChargePolicy> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/charging-cost/charge-policies/{id}/',
            path: {
                'id': id,
            },
            body: data,
        });
    }

    /**
     * @returns ChargePolicy 
     * @throws ApiError
     */
    public chargingCostChargePoliciesPartialUpdate({
id,
data,
}: {
/**
 * A unique integer value identifying this Πολιτική Τιμολόγησης.
 */
id: number,
data: ChargePolicy,
}): CancelablePromise<ChargePolicy> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/charging-cost/charge-policies/{id}/',
            path: {
                'id': id,
            },
            body: data,
        });
    }

    /**
     * @returns void 
     * @throws ApiError
     */
    public chargingCostChargePoliciesDelete({
id,
}: {
/**
 * A unique integer value identifying this Πολιτική Τιμολόγησης.
 */
id: number,
}): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/charging-cost/charge-policies/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns PolicyGroup 
     * @throws ApiError
     */
    public chargingCostPolicyGroupsList(): CancelablePromise<Array<PolicyGroup>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/charging-cost/policy-groups/',
        });
    }

    /**
     * @returns PolicyGroup 
     * @throws ApiError
     */
    public chargingCostPolicyGroupsCreate({
data,
}: {
data: PolicyGroup,
}): CancelablePromise<PolicyGroup> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/charging-cost/policy-groups/',
            body: data,
        });
    }

    /**
     * @returns PolicyGroup 
     * @throws ApiError
     */
    public chargingCostPolicyGroupsRead({
id,
}: {
/**
 * A unique integer value identifying this Ομάδα Τιμολόγησης.
 */
id: number,
}): CancelablePromise<PolicyGroup> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/charging-cost/policy-groups/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns PolicyGroup 
     * @throws ApiError
     */
    public chargingCostPolicyGroupsUpdate({
id,
data,
}: {
/**
 * A unique integer value identifying this Ομάδα Τιμολόγησης.
 */
id: number,
data: PolicyGroup,
}): CancelablePromise<PolicyGroup> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/charging-cost/policy-groups/{id}/',
            path: {
                'id': id,
            },
            body: data,
        });
    }

    /**
     * @returns PolicyGroup 
     * @throws ApiError
     */
    public chargingCostPolicyGroupsPartialUpdate({
id,
data,
}: {
/**
 * A unique integer value identifying this Ομάδα Τιμολόγησης.
 */
id: number,
data: PolicyGroup,
}): CancelablePromise<PolicyGroup> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/charging-cost/policy-groups/{id}/',
            path: {
                'id': id,
            },
            body: data,
        });
    }

    /**
     * @returns void 
     * @throws ApiError
     */
    public chargingCostPolicyGroupsDelete({
id,
}: {
/**
 * A unique integer value identifying this Ομάδα Τιμολόγησης.
 */
id: number,
}): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/charging-cost/policy-groups/{id}/',
            path: {
                'id': id,
            },
        });
    }

}
